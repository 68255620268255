.sidenav {
  width: 250px;
  background-color: #1a1c20;
  height: 100vh;
  overflow: auto;
  position: fixed;
  padding: 50px 15px;
}

.jslogo {
  width: 85px;
  margin: 0 auto;
  display: block;
}

.menu {
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
}

.menu a {
  width: 100%;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  letter-spacing: 1px;
  font-weight: 300;
  margin-top: 22px;
  padding: 8px 5px;
  text-transform: capitalize;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.menu a:hover {
  transform: scale(1.1);
  color: #f2c12e;
}

@media (min-width: 1080px) {
  .sidenav {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

@media (max-width: 1080px) {
  .sidenav {
    visibility: hidden;
    transition: 0.6s;
    z-index: 99;
  }
  .menu {
    margin-top: 70px;
  }
}

@media (max-width: 768px) {
    .sidenav{
        width: 220px;
    }

    .menu {
        margin-top: 50px;
    }
}



