.cardContainerOuter {
  width: calc(33.33% - 30px);
  margin: 0 15px;
}
.portfolioImg {
  width: 100%;
  min-height: 100%;
}

.portfolioCard {
  border: none !important;
}

.front,
.back {
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.2);
}

.projectName {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
  color: #f2c12e;
  text-align: center;
}

.projectType {
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
  padding-bottom: 0px;
  margin-bottom: 0;
  color: #ccc;
  text-align: center;
}

.portfolioCardKey {
  font-size: 17px;
  color: #f2c12e;
  margin-bottom: 0px;
  font-weight: 400;
}

.projectDescription {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.2;
  color: #ccc;
  margin-bottom: 5px;
  margin-top: 2px;
}

.projectInfoDetails {
  display: flex;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  color: #ccc;
}

.projectInfoDetails span {
  color: #f2c12e;
}

.projectInfoDetails span.icon {
  font-size: 18px;
}

.projectInfoDetails span.key {
  font-size: 15px;
  margin-top: -2px;
  display: block;
  width: 130px;
  margin-left: 6px;
  font-weight: 300;
}

.portfolioCard .back {
  position: relative;
}

.visitBtn {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  width: 100px;
  padding: 7px 0;
  text-align: center;
  display: block;
  color: #ffffff;
  background-color: #f2c12e;
  position: absolute;
  bottom: 15px;
  left: calc(50% - 50px);
}

.visitBtn:hover {
  color: #ffffff;
  background-color: #f2c12e;
}

.btn:hover,
.btn:focus,
.btn:active {
  outline: 0 !important;
}
/* entire container, keeps perspective */
.cardContainer {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}
/* flip the pane when hovered */
.cardContainer:not(.manual-flip):hover .card,
.cardContainer.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.cardContainer.static:hover .card,
.cardContainer.static.hover .card {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}
/* flip speed goes here */
.card {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #222529;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
}

/* front pane, placed above back */
.front {
  z-index: 2;
}

/* back, initially hidden pane */
.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
}

.card {
  border-radius: 4px;
  color: #444444;
}
.cardContainer,
.front,
.back {
  width: 100%;
  height: 370px;
}
.card .content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 10px 20px 20px;
}
.card .content .main {
  min-height: 160px;
}
.card .back .content .main {
  height: 215px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .front,
  .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }

  .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67);
  }
  .front {
    z-index: 4;
  }
  .cardContainer:not(.manual-flip):hover .back,
  .cardContainer.manual-flip.hover .back {
    z-index: 5;
    visibility: visible;
  }
}

@media (max-width: 1400px) {
  .cardContainerOuter {
    width: calc(50% - 30px);
  }
}

@media (max-width: 768px) {
  .cardContainerOuter {
    width: calc(100% - 30px);
  }
}