.TimelineBlock {
  position: relative;
  margin: 2em 0;
}
.TimelineBlock::after {
  clear: both;
  content: "";
  display: table;
}
.TimelineBlock:first-child {
  margin-top: 0;
}
.TimelineBlock:last-child {
  margin-bottom: 0;
}

.dots {
  position: absolute;
  top: calc(50% - 3px);
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f2c12e;
  box-shadow: 0 0 0 3px #656567, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.timelineContent {
  position: relative;
  margin-left: 40px;
  background: #222529;
  border-radius: 2px;
  color: #ccc;
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0 , 0.2);
  padding: 10px;
}
.timelineContent::after {
  clear: both;
  content: "";
  display: table;
}

.timelineContent .date {
  display: inline-block;
  float: left;
  padding: 0.8em 0;
  color: #f2c12e;
  font-size: 15px;
}
.timelineContent::before {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  right: 100%;
  height: 0;
  width: 0;
  border: 10px solid transparent;
  border-right: 10px solid #222529;
}

.timelineContent h2 {
   font-size: 22px;
   font-weight: 500;
   margin: 10px 0; 
}
  .timelineContent p {
    font-size: 14px;
    font-weight: 300;
  }

@media only screen and (min-width: 768px) {
  
}

@media only screen and (min-width: 992px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -1px;
  }

  .TimelineBlock {
    margin: 4em 0;
  }
  .TimelineBlock:first-child {
    margin-top: 0;
  }
  .TimelineBlock:last-child {
    margin-bottom: 0;
  }

  .dots {
    width: 20px;
    height: 20px;
    left: 50%;
    margin-left: -10px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .dots.isHidden {
    visibility: hidden;
  }
  .cssanimations .dots.bounceIn {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }

  .timelineContent {
    margin-left: 0;
    padding: 15px;
    width: calc(50% - 60px);
  }
  .timelineContent::before {
    left: 100%;
    border-color: transparent;
    border-left-color: #222529;
  }
  .timelineContent .date {
    position: absolute;
    width: 100%;
    left: 112%;
    top: calc(50% - 16px);
  }

  .TimelineBlock:nth-child(even) .timelineContent .date {
    left: auto;
    right: 112%;
    text-align: right;
  }

  .TimelineBlock:nth-child(even) .timelineContent {
    float: right;
  }
  .TimelineBlock:nth-child(even) .timelineContent::before {
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #222529;
  }
  
  .timelineContent.isHidden {
    visibility: hidden;
  }
  
  .cssanimations .timelineContent.bounceIn {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
   .cssanimations .TimelineBlock:nth-child(even) .timelineContent.bounceIn {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}


@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
