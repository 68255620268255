.header{
 height: 100vh;
 width: 100%;
 background-image: url(../../images/background.jpg);
 background-repeat: no-repeat;
 background-position: 100%;
 background-size: cover;
 position: relative;
}

.cover{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 1;
}

.toggler{
	position: absolute;
    top: 40px;
	right: 40px;
	width: 40px;
	height: 40px;
	z-index: 4;
	opacity: 0;
	cursor: pointer;
    display: none;
}

.hamberger{
    position: absolute;
    z-index: 3;
    top: 40px;
    right: 40px;
    width: 45px;
	height: 45px;
    display: none;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.hamberger div{
    width: 80%;
    height: 3px;
    background-color: #F2C12E;
    border-radius: 1.5px;
    position: absolute;
    transition: .4s ease;
}

.hamberger div::before,
.hamberger div::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    background-color: #F2C12E;
    transition: .4s ease;
}

.hamberger div::before{
    top: -10px;
}

.hamberger div::after{
    top: 10px;
}

.toggler:checked + .hamberger>div{
	transform: rotate(135deg);
}

.toggler:checked:hover + .hamberger>div{
	transform: rotate(225deg);
}

.toggler:checked + .hamberger>div:before, .toggler:checked + .hamberger>div:after{
	top: 0;
	transform: rotate(90deg);
}

.toggler:checked + .hamberger,
.toggler:checked{
    position: fixed;
}

.innerText{
    position: absolute;
    z-index: 2;
    right: 40px;
    bottom: 50px;
    text-align: right;
}

.innerText h2{
    font-size: 45px;
    color: #ffffff;
    font-weight: 300;
    line-height: 1;
    margin: 0;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.innerText h1{
    font-size: 90px;
    font-weight: 300;
    color: #F2C12E;
    line-height: 1;
    margin: 0;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.innerText h1 span{
    color: transparent;
    -webkit-text-stroke: 0.04vw #F2C12E;
    position: relative;
}

.innerText h1 span::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 12%;
    height: 100%;
    color: #F2C12E;
    line-height: 1.2;
    -webkit-text-stroke: 0vw #F2C12E;
    border-right: 2px solid #F2C12E;
    overflow: hidden;
    animation: animate 5s linear infinite;
}

@keyframes animate
{
    0%,10%, 100%
    {
        width: 0%;
    }
    70%,90%
    {
        width: 100%;
    }
}

@media (max-width: 1080px) {
  .toggler{
	display: block;
}

.hamberger{
    display: flex;
}
}

@media (max-width: 992px) {
  .innerText h2{
    font-size: 40px;
}

.innerText h1{
    font-size: 80px;
}
}

@media (max-width: 768px) {
    .innerText{
    right: 30px;
    bottom: 30px;
}
  .innerText h2{
    font-size: 34px;
}

.innerText h1{
    font-size: 70px;
}
}

@media (max-width: 578px) {
    .toggler{
    top: 20px;
	right: 20px;
}

.hamberger{
    top: 20px;
    right: 20px;
}
    .innerText{
    right: 30px;
    bottom: 100px;
}
  .innerText h2{
    font-size: 25px;
}

.innerText h1{
    font-size: 45px;
}
}