.educationCard{
  border-left: 3px solid #f2c12e;
  border-radius: 5px;
  width: 420px;
  padding: 5px 10px;
  margin: 20px 0;
}

.educationCard p{
  margin: 4px 0;
  text-align: end;
}

.institute{
  color: #ccc;
  text-align: left !important;
  font-size: 15px;
}

.course{
  color: #f2c12e;
  font-size: 17px;
}

.department{
  color: #ccc;
  font-size: 15px;
}

.duration{
  color: #ccc;
  font-size: 15px;
}

@media (max-width: 1400px) {
  .educationCard{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
}

@media (max-width: 576px) {
}

@media (max-width: 768px) {
}